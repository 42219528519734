import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Forms as formsGql } from "./../../graphql";
import Apollo from "../../Apollo";
import "./StepperDnd.css";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

const StepperDnd = () => {
  const { id } = useParams();
  const [stepperFormName, setStepperFormName] = useState();
  const [stepperFormIds, setStepperFormIds] = useState([]);
  const [Forms, setForms] = useState([]);
  const [dataChange, setDataChange] = useState(0);
  var formIds;

 
  useEffect(() => {
    Apollo.query(formsGql.findStepperForms, {stepper_form_id: id}, (res) => {
      if (res.data) {
        setStepperFormIds(res.data.formio_stepper_forms[0].forms);
        setStepperFormName(res.data.formio_stepper_forms[0].slug_name);
      }
    });
  },[dataChange]);
  


  const searchForms = () => {
    if (formIds) {
      var splitFormIds = formIds.split(",");
      var data = [];
      splitFormIds.map((formid) => {
        Apollo.query(formsGql.findById,{id: formid},(res) => {
          if (res.data) {
            data = [
              ...data,
              res.data.formio_forms
            ];
            setForms(data);
          }
        });
      });
    } else {
      alert("Please Enter Form Ids");
    }
  };

  const handleOnDragEnd = (result) => {
    if(result.destination && !(result.destination.droppableId === "FormsList" && result.source.droppableId === "stepperForms")){
      if(result.source.droppableId === "stepperForms" && result.destination.droppableId === "stepperForms") {
            var changedElement = stepperFormIds.splice(result.source.index, 1);
            stepperFormIds.splice(result.destination.index,0,changedElement[0]);
            delANDsave(stepperFormIds);
      }else if(result.destination.droppableId === "stepperForms" && result.source.droppableId === "FormsList") {
        stepperFormIds.splice(result.destination.index,0,
          {
            form_id: Forms[result.source.index][0].id,
            form: {
              title: Forms[result.source.index][0].title
            }
          });
        delANDsave(stepperFormIds);
      }
    } else {
      if(result.source.droppableId === "stepperForms")
      stepperFormIds.splice(result.source.index,1);
      delANDsave(stepperFormIds);    
    }
  };

  const delANDsave = (data) => {
    var data=[];
    stepperFormIds.map((e,i) => {
      data = [
        ...data,
        {
          stepper_form_id: id,
          form_id: e.form_id,
          order: i+1
        }
      ]
    });
    Apollo.query(formsGql.onStepperDelete,{ stepper_form_id: id },(res) => {})
      .then((success) => {
        Apollo.query(formsGql.onStepperInsert, { data: data }, (res) => {})
          .catch((err) => {
            alert("Something Wrong while saving and Stepper Form is deleted! Please make a New one");
          });
      })
      .catch((err) => {
        alert("Something Wrong");
      });
  }
  

  return (
    <div>
      <h1>
       {stepperFormName}
     </h1>
     <br></br>
      <div className="columns">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="column">
            <h3>Stepper Forms List :</h3>
            <Droppable droppableId="stepperForms">
              {(provided) => (
                <ul className="cardList" {...provided.droppableProps} ref={provided.innerRef}>
                      {stepperFormIds.map((e,i) => {
                      return (
                        <Draggable key={String(e.form_id)} draggableId={String(e.form_id)} index={i}>
                          {(provided) => (
                            <li className="cards" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                              <div> 
                                <p>Form Id : {e.form_id}</p>
                                <p><strong>{e.form.title}</strong></p>
                              </div>
                            </li>
                          )}
                        </Draggable>
                        );
                    })}
                    {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </div>
          <div className="column">
            <h3>
              Forms List :
            </h3>
              <div>
                <input
                  className="mt-3 mb-3"
                  type="text"
                  placeholder="Enter Form Ids :"
                  onChange={(e) => {
                    formIds = e.target.value;
                  }}
                />
                <br />
                <button className="btn btn-primary mt-3 mr-3" onClick={searchForms}>
                  Search
                </button>
              </div>
            <div>
              <Droppable droppableId="FormsList">
              {(provided) => (
              <ul className="formList" {...provided.droppableProps} ref={provided.innerRef}>
              {
              Forms.map((e,i) => {
                return (
                  <Draggable key={String(e.id)} draggableId={String(e.id)} index={i}>
                    {(provided) => (
                      <li className="cards" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <div> 
                          #{e[0].id} - 
                          <strong> {e[0].title}</strong>
                        </div>
                      </li>
                    )}
                  </Draggable>
                  );
              })}
              {provided.placeholder}
              </ul>
              )}
              </Droppable>
            </div>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default StepperDnd;
