import React from "react";
import PropTypes from "prop-types";
import {

} from 'react-bootstrap';


const BlankLayout = ({ children, noNavbar, noFooter }) => (
    <>
        <div class="account-pages"></div>
        <div class="clearfix"></div>
        {children}
    </>
);

BlankLayout.propTypes = {
    noNavbar: PropTypes.bool,
    noFooter: PropTypes.bool
};

BlankLayout.defaultProps = {
    noNavbar: true,
    noFooter: true
};

export default BlankLayout;
