import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { Forms as formsGql } from './../../graphql';
import Apollo from '../../Apollo';
import { Card, Form, Row, Col, Button, Modal, Accordion } from 'react-bootstrap';

const DataModal = (props) => {

  const data = props.data;

  const [recordset, setRecordset] = useState([]);
  const [details, setDetails] = useState(false);

  const columns = [
  {
    dataField: 'form_source',
    text: 'Form Source'
  }, {
      dataField: 'created_at',
      text: 'Posted on'
  }, {
      dataField: 'action',
      text: 'Action'
  }
  ];

  const viewData = (fdata) => (e) => {
    setDetails(fdata);
  }

  const fetchData = () => {
    Apollo.query(formsGql.findData, {form_id: parseInt(data.id)}, res => {
      //console.log(res);
        const newset = res.data.formio_submission.map((row) => {
            row['action'] = <><Button onClick={viewData(row.form_data)}>View</Button></>;
            return row;
        })
        setRecordset(newset);
    });
  }

  useEffect(fetchData, []);
  

  const renderDetails = () => {

    return <Modal show={true} size="md" dialogClassName="jsonDialog" onHide={() => setDetails(false)} backdrop="static" keyboard={false}>
    <Modal.Header closeButton>
  <Modal.Title>Data Captured</Modal.Title>
    </Modal.Header>
    <Modal.Body>      
      <Card variant="dark" className="p-2">
        {Object.keys(details).map((k,idx) => <Row key={idx}>
          <Col className="p-2">{k}</Col>
          <Col className="p-2">: {details[k]}</Col>
        </Row>)}
      </Card>
    </Modal.Body>
  </Modal>
  }


  const renderDatatable = () => {
    const pagination = paginationFactory({
        page: 1
    });

    return <>
        <ToolkitProvider
            keyField="uuid"
            data={recordset}
            columns={columns}
            pagination={pagination}
            search
        >
            {
                props => (
                    <div>
                        <Row>
                            <Col className="col-2"><h4>Filter Forms:</h4></Col>
                            <Col><Search.SearchBar {...props.searchProps} /></Col>
                        </Row>
                        <hr />
                        <BootstrapTable
                            {...props.baseProps}
                        />
                    </div>
                )
            }
        </ToolkitProvider>
    </>
  }

  return <Modal show={true} dialogClassName="jsonDialog" onHide={props.onClose} backdrop="static" keyboard={false}>
  <Modal.Header closeButton>
<Modal.Title>{data.title}'s Data </Modal.Title>
  </Modal.Header>
  <Modal.Body>    
  <h4>Form Id : {data.id}</h4>  
    {renderDatatable()}
    {details!==false && renderDetails()}
  </Modal.Body>
  <Modal.Footer>
      <Button onClick={props.onClose}>
          OK
  </Button>
  </Modal.Footer>                
</Modal>

}

export default DataModal;