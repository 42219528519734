import React from 'react';
import CURDForm from '../../Components/CRUD/CRUDForm';
import { useParams } from 'react-router-dom';



import * as Formio from './../../form.io';
import * as GraphQL from './../../graphql';
import * as DataTable from './../../datatable';

const Masters = () => {
    const { table } = useParams();
    const master = table.replace(/^./, table[0].toUpperCase());

    const props = {
        datatable: DataTable[master],
        graphql: GraphQL[master],
        formio: Formio[master]
    };
    return <>
        <h4>{master}</h4>
        <CURDForm {...props} />
    </>
}

export default Masters
