import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Form, Row, Container, Col, Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Forms as formsGql } from './../../graphql';
import { Form as Formio, FormBuilder } from 'react-formio';
import Apollo from '../../Apollo';
import DataModal from './DataModal';
import gql from "graphql-tag";
import "./FormioBuilder.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var i, totalLength, tableTitle;
window.onbeforeunload = function() { return "Your work will be lost."; };
const FormioReportFilterData = () => {
    const { id } = useParams();
    const [formId, setFormId] = useState(id);
    const [recordset, setRecordset] = useState([]);
    const [dataModal, showDataModal] = useState(false);
    const [submission,setSubmision] = useState({});
    const [show, setShow] = useState(false);    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formSchema, setFormSchema] = useState({});

    const columns = [
        {"text":"Action","dataField":"action"}
    ]

    const [formColumns, setFormColumns] = useState(columns);


    const openModal = useCallback((data) => {
        handleShow();
    },[])

    const viewFormData = (val,pos) => (e) => {
        i = pos;
        setSubmision(val.form_data);
        const idInt = parseInt(formId);
        Apollo.query(formsGql.getReportById, { id: idInt }, gqlquery => {
            if(gqlquery.data) {
            Apollo.query(gql (gqlquery.data.formio_report_filters[0].gql_query),gqlquery.data.formio_report_filters[0].gql_variables, res => {
                if (res.data) {
                Apollo.query(formsGql.findById, { id: (res.data.formio_submission[0].form_id) }, res => {
                    setFormSchema((res.data.formio_forms[0].comp));
                });
            }
            });
        }
        });
        openModal(val);
    }

    const fetchData = () => {
        const idInt = parseInt(formId);
        Apollo.query(formsGql.getReportById, { id: idInt }, gqlquery => {
            if (gqlquery.data) {
            Apollo.query(gql (gqlquery.data.formio_report_filters[0].gql_query),gqlquery.data.formio_report_filters[0].gql_variables, res => {
                if (res.data) {
                tableTitle = res.data.formio_submission[0].form_source;
                }
            });
        }
        });

        Apollo.query(formsGql.getReportById, { id: idInt }, gqlquery => {
            setFormColumns([...gqlquery.data.formio_report_filters[0].datatable_fields, {"text":"Action","dataField":"action"}]);
            if (gqlquery.data) {
            Apollo.query(gql (gqlquery.data.formio_report_filters[0].gql_query),gqlquery.data.formio_report_filters[0].gql_variables, res => {
                if (res.data) {
                let newset = [];
                totalLength = res.data.formio_submission.length;
                for(var i=0;i<totalLength; i++) {
                    //console.log(res.data.formio_submission[i])
                    res.data.formio_submission[i]['action'] = <><Button onClick={viewFormData(res.data.formio_submission[i],i)}>View</Button></>;
                    newset.push(res.data.formio_submission[i])
                }
                setRecordset(newset);
            }
            });
        }
        });
    };

    const viewNextData = () => {
        if (i===totalLength-1) i = 0;
        else i=i+1;
        navigate();
    }
    const viewPrevData = () => {
        if (i===0) i = totalLength-1;
        else i = i-1;
        navigate();
    }

    const navigate = () => {
        const idInt = parseInt(formId);
        Apollo.query(formsGql.getReportById, { id: idInt }, gqlquery => {
            if (gqlquery.data) {
            Apollo.query(gql (gqlquery.data.formio_report_filters[0].gql_query),gqlquery.data.formio_report_filters[0].gql_variables, res => {
                if (res.data) {
                setSubmision(res.data.formio_submission[i].form_data);
                openModal(res.data.formio_submission[i]);
                }
            });
        }
        });
    }

    useEffect(fetchData, [formId]);

    const submitFormData = (f) => {
        const idInt = parseInt(formId);
        Apollo.query(formsGql.getReportById, { id: idInt }, gqlquery => {
            if (gqlquery.data) {
            Apollo.query(gql (gqlquery.data.formio_report_filters[0].gql_query),gqlquery.data.formio_report_filters[0].gql_variables, res => {
                if (res.data) {
                    let changedFields = [];
                const keySetOne = Object.keys(f.data); 
                const keySetTwo = Object.keys(res.data.formio_submission[i].form_data);                 
                for(const key of keySetOne){ 
                    if(keySetTwo.includes(key)){  
                        console.log(key);
                       if (res.data.formio_submission[i].form_data[key] != f.data[key]) changedFields.push(key);
                    }
                   }
                Apollo.query(formsGql.updateFilteredFormData ,{uuid: res.data.formio_submission[i].uuid, data: f.data}, res =>{
                    toast("Form with UUID #"+ res.data.update_formio_submission.returning[0].uuid+ " Updated!");
                });
                console.log(JSON.stringify({sub_uuid: res.data.formio_submission[i].uuid, changes: changedFields}));
                Apollo.query(formsGql.insertLogUsingSubUUID ,{sub_uuid: res.data.formio_submission[i].uuid, changes: changedFields}, res =>{
                 });
                setSubmision(f.data);
                openModal(res.data.formio_submission[i]);
                }
            });
        }
        });
    }

    
        


    const renderDatatable = () => {
        const pagination = paginationFactory({
            page: 1
        });
    
        return <>
            <ToolkitProvider
                keyField="uuid"
                data={recordset}
                columns={formColumns}
                pagination={pagination}
                search
            >
                {
                    props => (
                        <div>
                            <Row>
                                <Col className="col-2"><h4>Filter Forms:</h4></Col>
                                <Col><Search.SearchBar {...props.searchProps} /></Col>
                            </Row>
                            <hr />
                            <BootstrapTable
                                {...props.baseProps}
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        </>
      }
    return <>
        <Container>
            <Card className="card-blue-border">
                <Card.Header>
                <ToastContainer/>
                </Card.Header>
                <Card.Body>
                    <div className="logs">
                    <h1>{tableTitle}</h1>
                    <span>
                    <Button>Logs</Button>
                    </span>
                    </div>
                    
                    <br></br>
                    {renderDatatable()}
                </Card.Body>
                <Modal show={show} dialogClassName="jsonDialog" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{tableTitle}</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="Navigation">
                        <Button className="right" onClick={viewPrevData}>PREV</Button>
                        {(i+1)+"/"+totalLength}
                        <Button onClick={viewNextData}>NEXT</Button>
                    </div>
                    <Formio form={formSchema} submission={{ data:submission}} onSubmit={submitFormData} />
                </Modal.Body>
                <Modal.Footer>
                
                    <Button onClick={handleClose}>OK</Button>
                </Modal.Footer>                
            </Modal>
            {dataModal!==false && <DataModal data={dataModal} onClose={() => showDataModal(false)} />}
            </Card>
        </Container>
    </>
}

export default FormioReportFilterData
