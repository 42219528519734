import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Form, Row, Container, Col, Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CMS as cmsGql, Forms as formGql } from './../../graphql';
import { Form as Formio, FormBuilder } from 'react-formio';
import Apollo from '../../Apollo';
import DataModal from '../Formio/DataModal';
import gql from "graphql-tag";
import "../Formio/FormioBuilder.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChevronCompactLeft } from 'react-bootstrap-icons';
import Utils from '../../Utils';
import { valueFromAST } from 'graphql';
import _ from 'lodash';
var i, totalLength, tableTitle;
window.onbeforeunload = function() { return "Your work will be lost."; };
const CMSFileData = () => {
    const { id } = useParams();
    const [formId, setFormId] = useState(id);
    const [recordset, setRecordset] = useState([]);
    const [dataModal, showDataModal] = useState(false);
    const [submission,setSubmision] = useState({});
    const [show, setShow] = useState(false);    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formSchema, setFormSchema] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [displayFlag, setDisplayFlag] = useState(false);
    const [modalTitle, setModalTitle] =useState('');
    const [formPos, setFormPos] = useState();
    const [addFlag, setAddFlag] = useState(false);
    const [frmSlug, setFrmSlug] = useState('');
    const [mutationQuery, setMutationQuery] = useState('');
    const columns = [
        {"text":"Action","dataField":"action"}
    ]

    const [formColumns, setFormColumns] = useState(columns);
    const [modalData, setModalData] = useState(false);

    const openModal = useCallback((data) => {
        handleShow();
    },[])

    
    useEffect(() => {       
        fetchData();
    }, [formId]);

    const viewFormData = (val,drawer,slug, pos) => (e) => {        
        i = pos;        
        setFormPos(pos);
        console.log(val, "slug")
        let fetchQuery = gql `${drawer}`;
        ///adding condition check for batches table
        if(slug == "et-product-batches-info"){
            Apollo.query(fetchQuery, {program_slug: val})
                .then(res => {
                    console.log(Object.values(res.data), "bt vals")
                    let obj = {submit : true, batches:Object.values(res.data)[0], program_slug: val};
                        console.log((Object.values(res.data)[0][0]), "fetcg res")
                    // console.log(Object.values(res.data)[0][0], "fetch")                        
                    // setSubmision(Object.values(res.data)[0][0]);
                    setSubmision(obj);
                    setModalData(res.data);
                })
                .catch(err => console.log(err, "fetch query error"));
        }else{
            ///this is applicable for noraml drawer(without relation/bulkdata)
            Apollo.query(fetchQuery, {id:val})
            .then(res => {
                console.log(Object.values(res.data)[0][0], "fetch")                        
                setSubmision(Object.values(res.data)[0][0]);
                setModalData(res.data);
            })
            .catch(err => console.log(err, "fetch query error"));    
        }
            
        Apollo.query(formGql.findByTitle, { title: slug }, res => {                
                setFormSchema((res.data.formio_forms[0].comp));
            });
        openModal(modalData);
    }

    const fetchData = () => {    
        const idInt = parseInt(formId);             
        Apollo.query(cmsGql.getCMSDrawerById, { id: idInt }, gqlquery => {
            //retrieved field for specific drawer program      
                                          
            if (gqlquery.data) {
                setFormColumns([...gqlquery.data.formio_cms_drawers[0].datatable_fields, {"text":"Action","dataField":"action"}]);
                tableTitle = gqlquery.data.formio_cms_drawers[0].drawer_table;
                setFrmSlug(gqlquery.data.formio_cms_drawers[0].formio_slug);
                setMutationQuery(gqlquery.data.formio_cms_drawers[0].mutation);
                const listQuery = gql `${ gqlquery.data.formio_cms_drawers[0].list_gql}`;

                Apollo.query(listQuery)
                    .then(res => {
                        if(res.data){                            
                            let newset = [];
                            let ar = Object.values(res.data);
                            console.log(Object.values(res.data)[0].length ,"leng")
                            totalLength = ar[0].length;
                            for(var i=0;i<totalLength; i++) {
                                // res.data.drawer_program[i]['action'] = <>
                                if(gqlquery.data.formio_cms_drawers[0].formio_slug == "et-product-batches-info"){
                                    ar[0][i]['action'] = <>
                                    <Button onClick={viewFormData(ar[0][i].program_slug,
                                            gqlquery.data.formio_cms_drawers[0].fetch_gql,
                                            gqlquery.data.formio_cms_drawers[0].formio_slug, i)}>View
                                    </Button></>;    
                                }else{
                                    ar[0][i]['action'] = <>
                                    <Button onClick={viewFormData(ar[0][i].id,
                                            gqlquery.data.formio_cms_drawers[0].fetch_gql,
                                            gqlquery.data.formio_cms_drawers[0].formio_slug, i)}>View
                                    </Button></>;
                                }
                                
                                newset.push(ar[0][i])                                
                            }                            
                            setRecordset(newset);
                            console.log(newset, "data")
                        }
                    })
                    .catch(err => console.log(err, "list err"));                                                     
            }
        });
    };

    const viewNextData = () => {
        if (i===totalLength-1) i = 0;
        else i=i+1;
        navigate();
    }
    const viewPrevData = () => {
        if (i===0) i = totalLength-1;
        else i = i-1;
        navigate();
    }

    const navigate = () => {
        const idInt = parseInt(formId);
        Apollo.query(cmsGql.getReportById, { id: idInt }, gqlquery => {
            if (gqlquery.data) {
            Apollo.query(gql (gqlquery.data.formio_report_filters[0].gql_query),gqlquery.data.formio_report_filters[0].gql_variables, res => {
                if (res.data) {
                setSubmision(res.data.formio_submission[i].form_data);
                openModal(res.data.formio_submission[i]);
                }
            });
        }
        });
    }
    


    const addNew = () => {
        setShow(true);
        Apollo.query(formGql.findByTitle, {title: frmSlug}, res => {            
            setFormSchema(res.data.formio_forms[0].comp);
        })    
    }

   

    const submitFormData = (f) => {
        console.log(f, "f etxt");
        const idInt = parseInt(formId);
        let mutationGql = gql `${mutationQuery}`;
        f.data =_.omit(f.data, 'submit');
        f.data = _.omit(f.data,'__typename');
        let tbl = `data_${tableTitle}`;
        mutateData(tableTitle, f.data, mutationGql);
    }

    const mutateData = (table, data, query) => {
        if(table == 'course'){
            Apollo.query(query, {data_course : data})
            .then(res => {
                if(res.data){
                    setShow(false);
                    fetchData();
                }                
            })
        }
        if(table == 'program'){
            Apollo.query(query, {data_program : data})
            .then(res => {
                if(res.data){
                    setShow(false);
                    fetchData();
                }
            })
        }
        if(table == 'batch'){            
            let data1 =[];            
            data.batches.map((batch, idx) => {
                batch = _.omit(batch, 'submit');
                batch = _.omit(batch, '__typename');
                batch = _.omit(batch, 'mentor_user_id');
                batch = {...batch, program_slug : data.program_slug};
                data1[idx] = batch;
            });
            Apollo.query(query, {program_slug :data.program_slug, data_program_batches : data1})
            .then(res => {                
                if(res.data){                    
                    setShow(false);
                    fetchData();
                }
            })
            .catch(err => console.log(err , "mut err bt"))
        }
    }
    
        


    const renderDatatable = () => {
        const pagination = paginationFactory({
            page: 1
        });
    
        return <>
            <ToolkitProvider
                keyField="uuid"
                data={recordset}
                columns={formColumns}
                pagination={pagination}
                search
            >
                {
                    props => (
                        <div>
                            <Row>
                                <Col className="col-2"><h4>Filter Forms:</h4></Col>
                                <Col><Search.SearchBar {...props.searchProps} /></Col>
                            </Row>
                            <hr />
                            <BootstrapTable
                                
                                {...props.baseProps}
                            />
                            {console.log(props.baseProps, "baseprops")}
                        </div>
                    )
                }
            </ToolkitProvider>
        </>
      }
    return <>
        <Container>
            <Card className="card-blue-border">
                <Card.Header>
                <ToastContainer/>
                </Card.Header>
                <Card.Body>
                    <div className="logs">
                    <h1>{tableTitle}</h1>
                    <span>
                    <Button onClick={addNew}>ADD</Button>
                    </span>
                    </div>
                    
                    <br></br>
                    {renderDatatable()}
                </Card.Body>
                <Modal show={show} dialogClassName="jsonDialog" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{tableTitle}</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="Navigation">
                        <Button className="right" onClick={viewPrevData}>PREV</Button>
                        {(i+1)+"/"+totalLength}
                        <Button onClick={viewNextData}>NEXT</Button>
                    </div>
                    <Formio form={formSchema} submission={{ data:submission}} onSubmit={submitFormData} />
                </Modal.Body>
                <Modal.Footer>
                
                    {/* <Button onClick={handleClose}>OK</Button> */}
                </Modal.Footer>                
            </Modal>
            {dataModal!==false && <DataModal data={dataModal} onClose={() => showDataModal(false)} />}
            </Card>
        </Container>
    </>
}

export default CMSFileData
