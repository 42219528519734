import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Form, Row, Container, Col, Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Forms as formsGql } from './../../graphql';
import { Form as Formio, FormBuilder } from 'react-formio';
import Apollo from '../../Apollo';
import DataModal from './DataModal';

const FormioData = () => {
    const { id } = useParams();
    const [formId, setFormId] = useState(id);
    const [recordset, setRecordset] = useState([]);
    const [dataModal, showDataModal] = useState(false);
    const [submission,setSubmision] = useState({});
    const [show, setShow] = useState(false);    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formSchema, setFormSchema] = useState({});

    const columns = [
        {
          dataField: 'form_source',
          text: 'Form Source'
        }, {
            dataField: 'created_at',
            text: 'Posted on'
        }, {
            dataField: 'action',
            text: 'Action'
        }
    ];

    const [formColumns, setFormColumns] = useState(columns);


    const openModal = useCallback((data) => {
        handleShow();
    },[])

    const viewFormData = (val) => (e) => {
        //console.log(val.form_data)
        setSubmision(val.form_data);
        openModal(val);
    }

    const fetchData = () => {
        const idInt = parseInt(formId);
        Apollo.query(formsGql.findById, { id: idInt }, res => {
            
            setFormSchema((res.data.formio_forms[0].comp));
        });
        

        Apollo.query(formsGql.findData, {form_id: idInt}, res => {
          //console.log(res);
            const newset = res.data.formio_submission.map((row) => {
                row['action'] = <><Button onClick={viewFormData(row)}>View</Button></>;
                return row;
            })
            setRecordset(newset);
        });
      }
    
      const filterData = () => {
        const idInt = parseInt(formId);
        Apollo.query(formsGql.findFilterData, {form_id: idInt , form_data: {year:"2020"}}, res => {
            setFormColumns([{ dataField: 'my-field2', text: 'My-Field2' },{ dataField: 'my-field', text: 'My-Field' },...formColumns ])
              const newset = res.data.formio_submission.map((row) => {
                  row['my-field'] = row.form_data.email;
                  row['my-field2'] = row.form_data.year;
                  row['action'] = <><Button onClick={viewFormData(row)}>View</Button></>;
                  return row;
              })
            setRecordset(newset);
          });
      }

    useEffect(fetchData, [formId]);




    const renderDatatable = () => {
        const pagination = paginationFactory({
            page: 1
        });
    
        return <>
            <ToolkitProvider
                keyField="uuid"
                data={recordset}
                columns={formColumns}
                pagination={pagination}
                search
            >
                {
                    props => (
                        <div>
                            <Row>
                                <Col className="col-2"><h4>Filter Forms:</h4></Col>
                                <Col><Search.SearchBar {...props.searchProps} /></Col>
                            </Row>
                            <hr />
                            <BootstrapTable
                                {...props.baseProps}
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        </>
      }
    

    return <>
        <Container>
            <Card className="card-blue-border">
                <Card.Body>
                    <h1>#{formId}</h1>
                    Form Filter Query:
                    <Form.Control as="textarea" placeholder="Leave a comment here" />
                    <Button onClick={filterData}>FilterData</Button>
                    <br></br>
                    {renderDatatable()}
                </Card.Body>
                <Modal show={show} dialogClassName="jsonDialog" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Your Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formio form={formSchema} submission={{ data:submission}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>OK</Button>
                </Modal.Footer>                
            </Modal>
            {dataModal!==false && <DataModal data={dataModal} onClose={() => showDataModal(false)} />}
            </Card>
        </Container>
    </>
}

export default FormioData
