import React, { useContext, useEffect, useState } from 'react';
import {
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import _ from "lodash";
import Utils from './../../Utils';
import axios from "axios";
import UserContext from './../../contexts/userContext';

const Login = (props) => {
    const [error, setError] = useState(false);
  const history = useHistory();
  const [loggingIn, showLogginIn] = useState(false);
  const [loginMessage,setLoginMsg] = useState("Login");
  const { goLogin } = useContext(UserContext);

  useEffect(()=>{
    
    if(!Utils.isLoggedIn()){
      
    }
    else{
      window.location.href= process.env.PUBLIC_URL + '/dashboard';
    }
  }, [Utils.getUserData()])

  
  const loginHandler = (e) => {
    alert("Please wait. We are processing your details...");
    setLoginMsg("Checking...")    
    var form = e.currentTarget;
    e.preventDefault();
    var data = {
      username: form.username.value,
      password: form.password.value
    };    
    axios
      .post(Utils.apiUrl('login'), {
        username: form.username.value,
        password: form.password.value,
      })
      .then(res => {        
        if(res.data.success){
          window.setTimeout(function(){
            let data = {...res.data.data, token:res.data._token};           
              Utils.setUserData(data);           
              window.location.href= process.env.PUBLIC_URL + '/dashboard'
          },1000);
        }        
      })
    
    const authCallback = (success) => {
      if(success){
        window.setTimeout(function(){
          window.location.href = process.env.PUBLIC_URL + '/dashboard';
        }, 2000);
        
      }
    }    
  };

    return <div class="wrapper-page">
        <div class="text-center">
            <a href="index.html" class="logo"><span>Admin<span>to</span></span></a>
            <h5 class="text-muted m-t-0 font-600">Responsive Admin Dashboard</h5>
        </div>
        <div class="m-t-40 card-box">
            <div class="text-center">
                <h4 class="text-uppercase font-bold m-b-0">Sign In</h4>
            </div>
            <div class="p-20">
                <form class="form-horizontal m-t-20" onSubmit={loginHandler}>

                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" required="" placeholder="Username" name="username" />
                        </div>
                    </div>


                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" type="password" required="" placeholder="Password" name="password" />
                        </div>
                    </div>

                    <div class="form-group ">
                        <div class="col-xs-12">
                            <div class="checkbox checkbox-custom">
                                <input id="checkbox-signup" type="checkbox" />
                                <label for="checkbox-signup">
                                    Remember me
                            </label>
                            </div>

                        </div>
                    </div>

                    <div class="form-group text-center m-t-30">
                        <div class="col-xs-12">
                            <button class="btn btn-custom btn-bordred btn-block waves-effect waves-light" type="submit">Log In</button>
                        </div>
                    </div>

                    <div class="form-group m-t-30 m-b-0">
                        <div class="col-sm-12">
                            <a href="page-recoverpw.html" class="text-muted"><i class="fa fa-lock m-r-5"></i> Forgot your password?</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 text-center">
                <p class="text-muted">Don't have an account? <a href="page-register.html" class="text-primary m-l-5"><b>Sign Up</b></a></p>
            </div>
        </div>
    </div >
}

export default Login;