import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { BlankLayout, DefaultLayout } from "./layouts"

// Route Pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Masters from "./pages/Masters/masters";
import FormioBuilder from "./pages/Formio/FormioBuilder";

import FormioData from "./pages/Formio/FormioData";
import FormioList from "./pages/Formio/FormioList";
import { Forms } from "./graphql";
import FormsList from "./pages/Formio/FormsList";
import DataList from "./pages/Formio/DataList";

import FormioReportFilterList from "./pages/Formio/FormioReportFilterList";
import FormioReportFilterData from "./pages/Formio/FormioReportFilterData";
import FormioReportBuilder from "./pages/Formio/FormioReportBuilder";

import StepperDnd from "./pages/Formio/StepperDnd";

import CMSDrawerList from "./pages/CMS/CMSDrawerList";
import CMSFileData from "./pages/CMS/CMSFileData";
import CMSDrawerBuilder from "./pages/CMS/CMSDrawerBuilder";


import JOLTSpecsList from "./pages/JOLT/JOLTSpecsList";
import JOLTSpecsData from "./pages/JOLT/JOLTSpecsData";
import JOLTSpecsBuilder from "./pages/JOLT/JOLTSpecsBuilder";

export default [
    {
        path: "/",
        exact: true,
        layout: BlankLayout,
        // component: () => < Redirect to = "/dashboard" / >
        component: Login
    },
    {
        path: "/login",
        exact: true,
        layout: BlankLayout,
        component: Login
    },
    {
        secure: true,
        path: "/dashboard",
        layout: DefaultLayout,
        component: Dashboard
    },
    {
        secure: true,
        path: "/formio/list",
        layout: DefaultLayout,
        component: FormioList
    }, {
        secure: true,
        path: "/formio/builder/:id",
        layout: DefaultLayout,
        component: FormioBuilder
    },
    {
        secure: true,
        path: "/formio/stepper/:id",
        layout: DefaultLayout,
        component: StepperDnd
    }, {
        secure: true,
        path: "/formio/capture/:id",
        layout: DefaultLayout,
        component: FormioData
    },
    {
        secure: true,
        path: "/masters/:table",
        layout: DefaultLayout,
        component: Masters
    },
    {
        secure: true,
        path: "/formio/forms",
        layout: DefaultLayout,
        component: FormsList
    },
    {
        secure: true,
        path: "/graphql/Froms",
        layout: DefaultLayout,
        component: { Forms }
    },
    {
        secure: true,
        path: "/formio/data",
        layout: DefaultLayout,
        component: DataList
    },
    {
        secure: true,
        path: "/formio/reports-filter/capture/:id",
        layout: DefaultLayout,
        component: FormioReportFilterData
    },
    {
        secure: true,
        path: "/formio/report-filter/builder/:id",
        layout: DefaultLayout,
        component: FormioReportBuilder
    },
    {
        secure: true,
        path: "/formio/reports",
        layout: DefaultLayout,
        component: FormioReportFilterList
    },
    {
        secure: true,
        path: "/jolt/filter/capture/:id",
        layout: DefaultLayout,
        component: JOLTSpecsData
    },
    {
        secure: true,
        path: "/jolt/filter/builder/:id",
        layout: DefaultLayout,
        component: JOLTSpecsBuilder
    },
    {
        secure: true,
        path: "/jolt/list",
        layout: DefaultLayout,
        component: JOLTSpecsList
    },
        {
        secure: true,
        path: "/cms/filter/capture/:id",
        layout: DefaultLayout,
        component: CMSFileData
    },
    {
        secure: true,
        path: "/cms/filter/builder/:id",
        layout: DefaultLayout,
        component: CMSDrawerBuilder
    },
    {
        secure: true,
        path: "/cms/list",
        layout: DefaultLayout,
        component: CMSDrawerList
    },

];