import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as DataTable from './../../datatable';
import {Forms as formsGql}  from './../../graphql';
import Apollo from '../../Apollo';
import { Card, Form, Row, Container, Col, Button} from 'react-bootstrap';

const FormioList = () => {
    const [recordset, setRecordset] = useState([]);
    const [currentValue, setCurrentValue] = useState({});
    const columns = [{
        dataField: 'id',
        text: 'ID'
    },{
        dataField: 'slug_name',
        text: 'Slug Name'
    },{
        dataField: 'view',
        text: 'view'
    }
];
    useEffect(() => {
        reloadDatatable();
    }, []);

    const reloadDatatable = () => {
        Apollo.query(formsGql.findStepperData, {}, res => {
            const newset=res.data.formio_stepper_forms.map((row)=>{
                row['view']=<Button>view</Button>;
                return row;
            })
            setRecordset(newset);
        });
    }

    function openwindow(id){
        window.open("/formio/stepper/"+id);
    }

    const selectRowProp = {
        mode: "radio",
        bgColor: 'grey',
        clickToSelect: true,
        hideSelectColumn: "true",
        selected: [currentValue.id],
        onSelect: async (row, isSelect, rowIndex, e) => {
            await Apollo.query(formsGql.findById, { "id": row.id }, res => {
                setCurrentValue(res.data.formio_forms)
                openwindow(row.id);
            });
        }
    };
    
    const renderDatatable = () => {
        const pagination = paginationFactory({
            page: 1
        });

        return <>
        <BootstrapTable
            keyField="id"
            data={recordset}
            columns={columns}
            pagination={pagination}
            selectRow={selectRowProp}
        />
    </>
    }
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col sm={7} className="pl-2">
                        <Card>
                            <Card.Header>
                                <strong>Datatable</strong>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {renderDatatable()}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default FormioList
