import gql from "graphql-tag";

export const Forms = {
    find: gql `
    query getRecords{
        formio_forms(order_by: {id: asc}){
          id
          title
        }
      }`,

    findByTitle: gql `
      query getRecord($title: String!){
        formio_forms(where: {title: {_eq: $title}}){
          id
          title
          comp
          
        }
      }`,

    findById: gql `
        query getRecord($id: Int!){
          formio_forms(where: {id: {_eq: $id}}){
            id
            title
            comp
          }
        }`,

    update: gql `
        mutation updateRecord(  
            $id: Int!,
            $title: String!
            $comp: jsonb!
          ) 
          {
          update_formio_forms(_set: {
            comp: $comp, 
            title: $title 
          }, where: {id: {_eq: $id}}){      
            returning{
              id
            }
          }
        }`,

    add: gql `mutation createNewRecord(  
              $comp: jsonb!
              $title: String!
            ) {
            insert_formio_forms(objects:{
              title: $title 
              comp: $comp
            }){
              returning{
                id
              }
            }
          }`,
    delete: gql `mutation deleteRecord($id: Int!){
      delete_formio_forms(where: {id: {_eq: $id}}){
        affected_rows
      }
  }`,
    findStepperData: gql `
  query MyQuery {
    formio_stepper_forms {
      id
      slug_name
    }
  }
  `,
    findStepperForms: gql `
    query MyQuery($stepper_form_id: Int) {
      formio_stepper_forms(where: {id: {_eq: $stepper_form_id}}) {
        forms(order_by: {order: asc}) {
          form_id
          form {
            title
          }
          order
        }
        slug_name
      }
    }
 `,
    updateStepperOrder: gql `mutation MyMutation($stepper_form_id: Int, $form_id: Int, $order: Int) {
    update_formio_stepper_forms_forms(where: {stepper_form_id: {_eq: $stepper_form_id}, form_id: {_eq: $form_id}}, _set: {order: $order}) {
      returning {
        id
      }
    }
  }
  `,
  getSubmissionBySource:gql `
    query getSubmission($form_source: String!){
        formio_submission(where: {form_source: {_eq: $form_source}},order_by: {created_at: desc}){
          uuid
          form_id
          form_data
          form_source
          created_at
        }
    }`,
    findData: gql `
    query getRecords($form_id: Int!){
        formio_submission(where: {form_id: {_eq: $form_id}},order_by: {created_at: desc}){
          uuid
          form_id
          form_data
          form_source
          created_at
        }
      }`,

    findFilterData: gql `
      query getFilterData($form_id: Int!, $form_data: jsonb!) {
        formio_submission(where: {form_id: {_eq: $form_id}, _and: {form_data: {_contains: $form_data}}}) {
          form_id
          form_data
          form_source
          form_meta_info
          uuid
        }
      }`,
    onStepperInsert: gql `
      mutation MyMutation($data: [formio_stepper_forms_forms_insert_input!]!) {
        insert_formio_stepper_forms_forms(objects: $data) {
          returning {
            form_id
            stepper_form_id
            order
          }
          affected_rows
        }
      }
      `,
    onStepperDelete: gql `
      mutation MyMutation($stepper_form_id: Int!) {
        delete_formio_stepper_forms_forms(where: {stepper_form_id: {_eq: $stepper_form_id}}) {
          returning {
            stepper_form_id
          }
          affected_rows
        }
      }
      `,
    getReportList: gql `
   query ReportsList {
     formio_report_filters(order_by: {id: asc}) {
       id
       query_slug
       query_description
     }
   }
   `,
    getReportById: gql `
   query ReportsFilterData($id: Int) {
     formio_report_filters(where: {id: {_eq: $id}}) {
       gql_query
       gql_variables
       query_slug
       datatable_fields
       query_description
     }
   }   
   `,
   getReportBySlug: gql `
   query ReportsFilterData($query_slug: String) {
     formio_report_filters(where: {query_slug: {_eq: $query_slug}}) {
       gql_query
       gql_variables
       query_slug
       datatable_fields
       query_description
     }
   }   
   `,

    addReportFilter: gql `mutation AddReportFilter($data: [formio_report_filters_insert_input!]!) {
    insert_formio_report_filters(objects: $data) {
      returning {
        id
      }
    }
  }`,
    updateReportFilter: gql `mutation UpdateReportFilter($id: Int, $data: formio_report_filters_set_input) {
    update_formio_report_filters(where: {id: {_eq: $id}}, _set: $data) {
      returning {
        id
      }
    }
  }
  `,
    updateFilteredFormData: gql `mutation MyMutation($uuid: uuid, $data: jsonb) {
    update_formio_submission(where: {uuid: {_eq: $uuid}}, _set: {form_data: $data}) {
      returning {
        uuid
      }
    }
  }
  `,
    insertLogUsingSubUUID: gql `mutation MyMutation($sub_uuid: uuid, $changes: jsonb) {
    insert_formio_log_version_one(object: {submission_uuid: $sub_uuid, changes: $changes}) {
      submission_uuid
    }
  }  
  `

}