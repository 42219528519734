import React, { useState, useEffect,useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';

import { Forms as formsGql } from '../../graphql';
import Apollo from '../../Apollo';
import { Card, Form, Row, Container, Col, Button, Modal, Accordion } from 'react-bootstrap';
import { Form as Formio, FormBuilder } from 'react-formio';
import JSONPretty from 'react-json-pretty';
import saveAs from 'save-as';
import 'react-json-pretty/themes/monikai.css';
import './FormsList.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import DataModal from './DataModal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { SearchBar } = Search;

const FormsList = () => {
    const [recordset, setRecordset] = useState([]);
    const [show, setShow] = useState(false);    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dataModal, showDataModal] = useState(false);
    const [formSchema, setFormSchema] = useState({});
    const [currentValue, setCurrentValue] = useState({});
    const columns = [{
        dataField: 'id',
        text: 'ID'
    }, {
        dataField: 'title',
        text: 'Title'
    }, {
        dataField: 'action',
        text: 'Action'
    }
    ];
    const openModal = useCallback((form_id) => {
        Apollo.query(formsGql.findById, { "id": form_id }, res => {
            setFormSchema((res.data.formio_forms[0].comp));
        });
        handleShow();
    },[])
    const reloadDatatable = useCallback ((count) => {
        
        const viewData = (val) => (e) => {
            window.location.href = "/formio/capture/" + val;
        }
        
        const view = (val) => (e) => {
            openModal(val);
        }

        const edit = (val) => (e) => {
            window.location.href = "/formio/builder/" + val;
        }

        Apollo.query(formsGql.find, {}, res => {
            const newset = res.data.formio_forms.map((row) => {
                row['action'] = <><Button name  onClick={view(row.id)}>View</Button>{'  '}                
                <Button onClick={edit(row.id)}>Edit</Button>{'  '}
                <Button onClick={viewData(row.id)}>View Data</Button></>;
                return row;
            })
            setRecordset(newset);
        });
    },[openModal])

    useEffect(() => {

        reloadDatatable();
    }, [reloadDatatable]);

 

    const addNew = () => {
        Apollo.mutate(formsGql.add, { comp: "{}", title: "" }, res => {
            //console.log(res.data.insert_formio_forms.returning[0].id)
            toast("New Form Added id#"+ res.data.insert_formio_forms.returning[0].id);
            reloadDatatable();
        });
    }


    

    const exportToJson = (jsonObject) => (e) => {
        const jo = _.pickBy(jsonObject, _.identity);
        //console.log(jo);
        let blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(jsonObject, null, 1)))], { type: 'text/plain;charset=utf-8' })
        saveAs(blob)



    }
    const selectRowProp = {
        mode: "radio",
        bgColor: 'grey',
        clickToSelect: true,
        hideSelectColumn: "true",
        selected: [currentValue.id],
        onSelect: async (row, isSelect, rowIndex, e) => {
            
            await Apollo.query(formsGql.findById, { "id": row.id }, res => {
                setCurrentValue(res.data.formio_forms)
            });
        }
    };
    const renderDatatable = () => {
        const pagination = paginationFactory({
            page: 1
        });

        return <>
            <ToolkitProvider
                keyField="id"
                data={recordset}
                columns={columns}
                pagination={pagination}
                search
            >
                {
                    props => (
                        <div>
                            <Row>
                                <Col className="col-2"><h4>Filter Forms:</h4></Col>
                                <Col><SearchBar {...props.searchProps} /></Col>
                            </Row>
                            <BootstrapTable
                                {...props.baseProps}
                                keyField="id"
                                data={recordset}
                                columns={columns}
                                pagination={pagination}
                                selectRow={selectRowProp}
                            />
                            </div>
                    )
                }
            </ToolkitProvider>
        </>
    }
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col sm={12} className="pl-1">
                        <Card>
                            <Card.Header>
                            <ToastContainer/>
                                <strong>Datatable</strong>
                                <Button className="float-right" onClick={addNew}>Add</Button>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {renderDatatable()}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
            <Modal show={show} dialogClassName="jsonDialog" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Your Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formio form={formSchema} />
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <Button >View Json</Button>
                                </Accordion.Toggle>
                                <Button float-right onClick={exportToJson(formSchema)}>Download</Button>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body><Card.Body><JSONPretty id="json-pretty" style={{ fontSize: "1.1em" }} data={formSchema} mainStyle="padding:1em" valueStyle="font-size:1.5em"></JSONPretty></Card.Body></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>
                        OK
                </Button>
                </Modal.Footer>                
            </Modal>
            {dataModal!==false && <DataModal data={dataModal} onClose={() => showDataModal(false)} />}
        </Card>
    );
}

export default FormsList
