import gql from "graphql-tag";

export const CMS = {
    list: gql `
      query getCMSDrawerList {
        formio_cms_drawers(order_by: {id: asc}) {
          id
          cms_drawer_slug
    			description
    			formio_slug
    			mutation
          drawer_table
          list_gql
          fetch_gql
        }
    }`,
    findByTitle: gql `
      query getCMSDrawerList($title: String!) {
        formio_cms_drawers(where: {title: {_eq: $title}}) {
          id
          cms_drawer_slug
    			description
    			formio_slug
    			jolt_specs_slug
        }
    }`,
    findById: gql `
      query getCMSDrawerList($id: Int!) {
        formio_cms_drawers(where: {id: {_eq: $id}}) {
          id
          cms_drawer_slug
          description
          formio_slug
          mutation
        }
    }`,

    getSubmissionsById: gql`
      query MyQuery($_contains: jsonb = {}) {
        formio_submission(where: {form_source: {_eq: "et-product-info"}, form_data: {_contains: $_contains}}, order_by: {created_at: desc}) {
          form_data
          form_source
          created_at
          form_id
          uuid
        }
    }`,

    delete: gql `mutation deleteRecord($id: Int!){
      delete_formio_forms(where: {id: {_eq: $id}}){
        affected_rows
      }
    }`,
    findData: gql `
      query getRecords($form_id: Int!){
        formio_submission(where: {form_id: {_eq: $form_id}},order_by: {created_at: desc}){
          uuid
          form_id
          form_data
          form_source
          created_at
        }
    }`,
    findFilterData: gql `
      query getFilterData($form_id: Int!, $form_data: jsonb!) {
        formio_submission(where: {form_id: {_eq: $form_id}, _and: {form_data: {_contains: $form_data}}}) {
          form_id
          form_data
          form_source
          form_meta_info
          uuid
        }
    }`,
    getCMSDrawerById: gql `
      query getCMSDrawer($id:Int!) {
        formio_cms_drawers(where: {id: {_eq: $id}}) {
          id
          cms_drawer_slug
    			description
    			formio_slug
    			mutation
          datatable_fields
          drawer_table
          list_gql
          fetch_gql
        }
    }`,
    addCMSDrawer: gql `mutation AddCMSDrawer($data: [formio_cms_drawers_insert_input!]!) {
      insert_formio_cms_drawers(objects: $data,
        on_conflict: {constraint: cms_drawers_cms_drawer_slug_key, update_columns: cms_drawer_slug}) {
        returning {
          id
        }
      }
    }`,
    UpdateCMSDrawer: gql `mutation UpdateCMSDrawer($id: Int, $data: formio_cms_drawers_set_input) {
      update_formio_cms_drawers(where: {id: {_eq: $id}}, _set: $data) {
        returning {
          id
        }
      }
    }`,
    addCmsToSubmission: gql `
      mutation addCMSPageToFormioSubmission($form_id : Int, $form_source: String, $form_data : jsonb, $form_meta_info : jsonb ) {
      insert_formio_submission(objects: {form_data: $form_data, form_id: $form_id, form_source: $form_source, form_meta_info: $form_meta_info}){
        returning {
          uuid
        }
      }
    }`,
    

}