import React, { useState, useEffect, useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';

import { Forms as formsGql } from './../../graphql';
import Apollo from '../../Apollo';
import { Card, Form, Row, Container, Col, Button, Modal, Accordion } from 'react-bootstrap';
import { Form as Formio, FormBuilder } from 'react-formio';
import JSONPretty from 'react-json-pretty';
import saveAs from 'save-as';
import 'react-json-pretty/themes/monikai.css';
import './FormsList.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import DataModal from './DataModal';
import Select from 'react-select';
import './DataList.css'

const { SearchBar } = Search;
const DataList = () => {
    const [recordset, setRecordset] = useState([]);
    const [show, setShow] = useState(false);    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dataModal, showDataModal] = useState(false);
    const [formSchema, setFormSchema] = useState({});
    const [currentValue, setCurrentValue] = useState({});
    const [selectedOption, setSelectedOption] = useState([]);
    const [currentOption,setCurrentOption]=useState([]);
    
    const reloadDatatable = useCallback(() => {
        const viewData = (id, title) => (e) => {
            showDataModal({id: id, title: title});
        }
        const view = (val) => (e) => {
            openModal(val);
        }
        const edit = (val) => (e) => {
            window.location.href = "/formio/builder/" + val;
        }

        Apollo.query(formsGql.find, {}, res => {
            const newset = res.data.formio_forms.map((row) => {
                row['action'] = <><Button onClick={viewData(row.id, row.title)}>View Data</Button></>;
                return row;
            })
            
            setRecordset(newset);
        });

        //for dropdown
        Apollo.query(formsGql.find, {}, res => {
            const optn=res.data.formio_forms.map(d=>({
                "value":d.id,
                "label":d.title,
            }))
            setSelectedOption(optn)
            // //console.log("hi ",optn[0].value,optn[0].label)
        });
    })

    useEffect(() => {
        //reloadDatatable();
    }, [reloadDatatable]);

    const openModal = (form_id) => {
        Apollo.query(formsGql.findById, { "id": form_id }, res => {
            setFormSchema((res.data.formio_forms[0].comp));
        });
        handleShow();
    }

    const addNew = () => {
        Apollo.mutate(formsGql.add, { comp: "{}", title: " " }, res => {
            //console.log(res);
            reloadDatatable();
        });
    }



    const exportToJson = (jsonObject) => (e) => {
        const jo = _.pickBy(jsonObject, _.identity);
        let filename = "export.txt";
        let contentType = "application/json;charset=utf-8;";

        let blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(jsonObject, null, 1)))], { type: 'text/plain;charset=utf-8' })
        saveAs(blob)       
    }
    function openwindow(id){
        //console.log("opening window"+id);
        //// window.open("https://formspanel.tslforms.com/formio/builder/"+id);
    }
    const selectRowProp = {
        mode: "radio",
        bgColor: 'grey',
        clickToSelect: true,
        hideSelectColumn: "true",
        selected: [currentValue.id],
        onSelect: async (row, isSelect, rowIndex, e) => {
            //console.log(row.id);
            ////console.log(isSelect);
            ////console.log(rowIndex);
            await Apollo.query(formsGql.findById, { "id": row.id }, res => {
                setCurrentValue(res.data.formio_forms)
                //console.log(res.data.formio_forms);
                openwindow(row.id);
            });
        }
    };
   
    const renderDatatable = () => {
        const pagination = paginationFactory({
            page: 1
        });
      
        const handleChange = (e) => {
            setCurrentOption({id:e.value,title:e.label});
            //console.log('current optn 0 ',currentOption)
        }
        //console.log('current optn 1 ',currentOption)
        const viewData = (e) => {
            showDataModal({id:currentOption.id, title: currentOption.title});
                //console.log('current optn 1 ',currentOption)
            }
        return( 
            <>
                <Select className='dropdown_list'
                    placeholder="Select Form"
                    options={selectedOption}
                    onChange={handleChange}
                />
                <Button className='mt-2' onClick={viewData}>View Data</Button>
            </>
        )
    }
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col sm={12} className="pl-1">
                        <Card>
                            <Card.Header>
                                <strong>Datatable</strong>
                                {/* <Button className="float-right" onClick={addNew}>Add</Button> */}
                            </Card.Header>
                            <Card.Body className='dropdown_1'>
                                <Card.Text>
                                    {renderDatatable()}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>

            </Card.Footer>
            <Modal show={show} dialogClassName="jsonDialog" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Your Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formio form={formSchema} />
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <Button >View Json</Button>
                                </Accordion.Toggle>
                                <Button float-right onClick={exportToJson(formSchema)}>Download</Button>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body><Card.Body><JSONPretty id="json-pretty" style={{ fontSize: "1.1em" }} data={formSchema} mainStyle="padding:1em" valueStyle="font-size:1.5em"></JSONPretty></Card.Body></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>
                        OK
                </Button>
                </Modal.Footer>                
            </Modal>
            {dataModal!==false && <DataModal data={dataModal} onClose={() => showDataModal(false)} />}
        </Card>
    );
}

export default DataList
