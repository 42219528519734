import React, { useState, useEffect } from 'react';
import { Form } from 'react-formio';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Apollo from '../../Apollo.js';

import './CRUDForm.css';

const CRUD = (props) => {
    const [currentValue, setCurrentValue] = useState({});
    const [currentActionState, setCurrentActionState] = useState("NEW");
    const [recordset, setRecordset] = useState([]);
    //const [currentTable, setCurrentTable] = useState(props.current_table);
    const columns = props.datatable.fields;

    useEffect(() => {
        reloadDatatable();
    }, []);

    const reloadDatatable = () => {
        Apollo.query(props.graphql.find, {}, res => {
            //console.log(res.data[props.datatable.schema_table]);
            setRecordset(res.data[props.datatable.schema_table]);
        });
    }


    const deleteRecordSet = async (recordID) => {
        const id = parseInt(recordID);
        if (id > 0) {
            await Apollo.mutate(props.graphql.delete, { id: id }, res => {
                reloadDatatable();
            });
        }
    }


    const renderDatatable = () => {

        const pagination = paginationFactory({
            page: 1
        });



        const selectRowProp = {
            mode: "radio",
            bgColor: 'grey',
            clickToSelect: true,
            hideSelectColumn: "true",
            selected: [currentValue.id],
            onSelect: async (row, isSelect, rowIndex, e) => {
                //console.log(row);
                await Apollo.query(props.graphql.findById, { "id": row.id }, res => {
                    setCurrentValue(res.data[props.datatable.schema_table][0])
                    setCurrentActionState("EDIT");
                });
            }
        };

        return <BootstrapTable
            keyField="id"
            data={recordset}
            columns={columns}
            selectRow={selectRowProp}
            pagination={pagination}

        />

    }


    const renderForm = () => {

        const submitFormIO = async (val) => {
            const id = parseInt(val.data.id);
            delete val.data.submit; //Cleanup
            delete val.data.__typename; //Cleanup
            //console.log(val.data);
            if (id === 0) {
                await Apollo.mutate(props.graphql.add, val.data, res => {
                    //setRecordset(res.data[props.datatable.schema_table]);
                    //console.log(res);
                    reloadDatatable();
                });
            } else if (id > 0) {
                await Apollo.mutate(props.graphql.update, val.data, res => {
                    //console.log(res)
                    reloadDatatable();
                });
            }
        }

        const formValue = {
            data: currentValue
        }

        return <Form
            form={props.formio}
            submission={formValue}
            onSubmit={submitFormIO}
        ></Form >
    }

    const addNew = () => {
        setCurrentValue({ id: 0 });
        setCurrentActionState("ADD");

    }


    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col sm={7} className="pl-2">
                        <Card>
                            <Card.Header>
                                <strong>Datatable</strong>
                                <Button variant="outline-dark" className="float-right" onClick={addNew} size="sm">Add New</Button>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {renderDatatable()}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={5} className="pl-2">
                        <Card>
                            <Card.Header>
                                <strong>Data</strong> [{currentActionState}#{currentValue.id}]
                                {(currentActionState === "EDIT") && <Button variant="outline-danger" className="float-right" size="sm" onClick={() => deleteRecordSet(currentValue.id)}>Delete</Button>}
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {renderForm()}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}


export default CRUD;
