// import { Callbacks } from 'jquery';
import _ from 'lodash';
import moment from 'moment';

const Utils = {
    
    apiUrl : (path, params= '') => {
        let url = process.env.REACT_APP_JWT_SLS  + path;
        if( params && !_.isEmpty(params)){
            url += '?' + encodeURI(params);
        }
        return url;
    },

    apiHeaders: (extraparams = null) => {
        var headers = {
            "headers": {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS, PATCH",
                'Accept': 'application/json',
                'x-api-key': '$2a$08$tdn6aucbqRv7YW9BLZgfseK95nzhDnqMygW2wf9HkQzuGzKBWQHCO'        
            }
        };

        try{
            const userData = Utils.getUserData();
            if(userData && _.get(userData, 'token', false)){
                headers['headers']['token'] = userData.token;
            }
            // if(token){
            //     ret.headers['Authorization'] = `Bearer ${token}`;
            // }
        } catch{
            //do nothing
        }

        // if(headers){
        //     headers.forEach(h => {
        //         ret['headers'][Object.keys(h)[0]] = Object.values(h)[0];
        //     });
        // }
        return headers;
    },

    unsetSession: (token, callback) => {
        localStorage.removeItem(token);
        if (callback){
            callback();
        }       
    },

    setSession: (token, udata, callback = null) => {
        var ud = Utils.getSession(token);
        if(ud) {
            udata = {
                ...ud,
                ...udata
            }; //== overwrite received data
        }
        localStorage.setItem(token, JSON.stringify(udata));
        if(callback){
            callback();
        }
    },

    getSession: (token) => {
        var udata = localStorage.getItem(token);
        console.log(udata, "udata")
        if(udata && udata !== 'null') {
            return JSON.parse(udata);
        }
        return false;
    },

    isLoggedIn: () => {
        return true ; // alway loggin;
        const data = Utils.getUserData();
            return _.get(data, 'token', false) !== false;                
    },

    // isLoggedIn: () => {
    //     console.log(process.env.REACT_APP_ADMIN_TOKEN)
    //     return Utils.getSession(process.env.REACT_APP_ADMIN_TOKEN) !==false;
    // },

    getUserUUID: (token) => {
        const udata = Utils.getSession(process.env.REACT_APP_ADMIN_TOKEN);
        return udata.data.uuid;
    },

    getUserEmail: (token) => {
        const udata = Utils.getSession(process.env.REACT_APP_ADMIN_TOKEN);
        return udata.data.email;
    },

    getUserName: () => {
        const udata = Utils.getSession(process.env.REACT_APP_ADMIN_TOKEN);
        return udata.data.name;
    },

    setUserData: (data) => {
        
        const userData = _.get(data, 'token', false);
        console.log(userData, "in utils set")
        if(userData === false){
            localStorage.removeItem(process.env.REACT_APP_APPNAME + '-userData');
        }
        else{
         localStorage.setItem(process.env.REACT_APP_APPNAME + '-userData', JSON.stringify(data));
        }
    },
    getUserData: () => {
        let userData = localStorage.getItem(process.env.REACT_APP_APPNAME + '-userData');
        if(userData){
            return JSON.parse(userData);
        }
        else{
            return false;
        }
    },

};

export default Utils;
