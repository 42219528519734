import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Container, Col, Button, Accordion } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastHeader from 'react-bootstrap/ToastHeader';
import { Form as Formio } from 'react-formio';
import { Forms as formsGQL } from './../../graphql';
import Apollo from '../../Apollo';
import { useParams } from 'react-router-dom';
import "./FormioBuilder.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var reportFilterJson = {};


function IsJsonString(val) {
    try {
        JSON.parse(val);
    } catch (e) {
        return false;
    }
    return true;
}

const ReportEditForm = () => {
    const { id } = useParams();
    const [formSchema, setFormSchema] = useState({});
    const [reload, setReload] = useState(0);
    const [textboxValue, setTextboxValue] = useState();
    const [formId, setFormId] = useState(id);
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState();
    //const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [submission,setSubmision] = useState({});

    useEffect(() => {
        const idInt = parseInt(formId);
        Apollo.query(formsGQL.findByTitle, { title: 'et-report-filter-template' }, res => {
             setFormSchema((res.data.formio_forms[0].comp));
         });
        Apollo.query(formsGQL.getReportById, { id: idInt }, gqlquery => {
            if(gqlquery.data) {
                setTitle(gqlquery.data.formio_report_filters[0].query_slug);
                let reportFilterJson = gqlquery.data.formio_report_filters[0];
                let gql_variables = JSON.stringify(reportFilterJson.gql_variables);
                let datatable_fields = JSON.stringify(reportFilterJson.datatable_fields);
                reportFilterJson.gql_variables = gql_variables;
                reportFilterJson.datatable_fields = datatable_fields;
                setSubmision(reportFilterJson);
        }
        });
    }, [formId]);
    const valueSave = async () => {
        //console.log(JSON.stringify({ id: formId, data: textboxValue}));
        await Apollo.mutate(formsGQL.updateReportFilter, { id: formId, data: textboxValue}, res => {
            console.log(res);
            toast("Form Updated!");
        });
        handleShow();
    }
    const handleChange = (val) => {
        //console.log(val.target.value);
        // Validate JSON
        const vali = (val.target.value);
        //console.log(IsJsonString(vali));
        if (IsJsonString(vali)) {
            setFormSchema(JSON.parse(val.target.value));
        }
        else setFormSchema({});
    }

    const submitReportFilterEdit = (f) => {
        reportFilterJson.gql_query =  f.data.gql_query;
        reportFilterJson.query_description = f.data.query_description;
        reportFilterJson.query_slug = f.data.query_slug;
        if(IsJsonString(f.data.datatable_fields) && f.data.datatable_fields) reportFilterJson.datatable_fields = JSON.parse(f.data.datatable_fields);
        else reportFilterJson.datatable_fields = {};
        if(IsJsonString(f.data.gql_variables) && f.data.gql_variables) reportFilterJson.gql_variables = JSON.parse(f.data.gql_variables);
        else reportFilterJson.gql_variables = {};
        setTextboxValue(reportFilterJson);
        setSubmision(f.data);
    }


    return <>
        <Container>
            <Card className="card-blue-border">
                <Card.Body>

                    <Row>
                        <Col> <Form.Label>Enter Title</Form.Label></Col>
                        <Col> <Form.Control onChange={(text) => { setTitle(text.target.value); }} value={title} /></Col>
                    </Row>
                    <hr></hr>
                    <Formio form={formSchema} submission={{ data:submission}} onSubmit={submitReportFilterEdit} />
                </Card.Body>
            </Card>
            <hr></hr>
        </Container>

        <Container>
            <Card className="card-blue-border">
            <Card.Header>
                <ToastContainer/>
                </Card.Header>
                <Card.Body>
                    <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide>
                        <ToastHeader>
                            <strong className="mr-auto">Saved</strong>
                        </ToastHeader>
                    </Toast>
                    <Button onClick={valueSave} className="align-center btn-block btn-info">Save</Button>
                    <Accordion>
                        <Card.Header>
                            <Accordion.Toggle as={Button} eventKey="0">
                                Import
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body><Form.Control as="textarea" value={textboxValue} rows="10" onChange={handleChange} /></Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </Card.Body>
            </Card>

        </Container>

    </>
}

export default ReportEditForm
