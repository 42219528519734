import React, { createContext, useState } from 'react';
import axios from 'axios';
import Utils from './../Utils';
import _ from 'lodash';

const UserContext = createContext();

const UserProvider = (props) => {
    const [ userData, setUserData ] = useState(Utils.getUserData());

    const goLogin = ({userName, password}, callback) => {        
        axios.post(Utils.apiUrl('login'), {userName, password}, Utils.apiHeaders())
            .then(res => {
                callback(res.data.data.success);
                if(res.data.data.success){
                    let data = {...res.data.data, token:res.data.token};
                    Utils.setUserData(data);
                }
            })
            .catch(err => {
                console.log("Login Error : " ,err);
            })
    }

    return <UserContext.Provider value={{goLogin}}>
        {props.children}
    </UserContext.Provider>

}

export default UserContext;

export {UserProvider};