import React from 'react';
import './App.css';
import _ from 'lodash';
import routes from "./routes";
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Utils from './Utils';
import { BlankLayout } from './layouts';
import Login from './pages/Auth/Login';
import { UserProvider } from './contexts/userContext';

const App = (props) => {

  const RoutedLayout = () => {
    return <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div className="h-100">
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props => {
                if(!Utils.isLoggedIn()){
                  return(
                    <BlankLayout {...props}>
                      <Login />
                    </BlankLayout>
                  );
                }
                else{
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }
                
              })}
            />
          );
        })}
      </div>
    </Router>
  }

  const Provider = () => {
    return <UserProvider>
        <RoutedLayout />
    </UserProvider>
  }

  return <Provider />;
}


export default App;