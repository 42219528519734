import gql from "graphql-tag";

export const JOLT = {
    list: gql `
    query getJOLTList {
      formio_jolt_specs(order_by: {id: asc}) {
        id
        jolt_slug
        description
      }
    }`,

    findByTitle: gql `
      query getRecord($title: String!){
        formio_forms(where: {title: {_eq: $title}}){
          id
          title
          comp
          
        }
      }`,

    findById: gql `
        query getRecord($id: Int!){
          formio_forms(where: {id: {_eq: $id}}){
            id
            title
            comp
          }
        }`,

    update: gql `
        mutation updateRecord(  
            $id: Int!,
            $title: String!
            $comp: jsonb!
          ) 
          {
          update_formio_forms(_set: {
            comp: $comp, 
            title: $title 
          }, where: {id: {_eq: $id}}){      
            returning{
              id
            }
          }
        }`,

    add: gql `mutation createNewRecord(  
              $comp: jsonb!
              $title: String!
            ) {
            insert_formio_forms(objects:{
              title: $title 
              comp: $comp
            }){
              returning{
                id
              }
            }
          }`,
    delete: gql `mutation deleteRecord($id: Int!){
      delete_formio_forms(where: {id: {_eq: $id}}){
        affected_rows
      }
  }`,
    findStepperData: gql `
  query MyQuery {
    formio_stepper_forms {
      id
      slug_name
    }
  }
  `,
    findStepperForms: gql `
    query MyQuery($stepper_form_id: Int) {
      formio_stepper_forms(where: {id: {_eq: $stepper_form_id}}) {
        forms(order_by: {order: asc}) {
          form_id
          form {
            title
          }
          order
        }
        slug_name
      }
    }
 `,
    findData: gql `
    query getRecords($form_id: Int!){
        formio_submission(where: {form_id: {_eq: $form_id}},order_by: {created_at: desc}){
          uuid
          form_id
          form_data
          form_source
          created_at
        }
      }`,

    findFilterData: gql `
      query getFilterData($form_id: Int!, $form_data: jsonb!) {
        formio_submission(where: {form_id: {_eq: $form_id}, _and: {form_data: {_contains: $form_data}}}) {
          form_id
          form_data
          form_source
          form_meta_info
          uuid
        }
      }`,
    onStepperInsert: gql `
      mutation MyMutation($data: [formio_stepper_forms_forms_insert_input!]!) {
        insert_formio_stepper_forms_forms(objects: $data) {
          returning {
            form_id
            stepper_form_id
            order
          }
          affected_rows
        }
      }
      `,
    onStepperDelete: gql `
      mutation MyMutation($stepper_form_id: Int!) {
        delete_formio_stepper_forms_forms(where: {stepper_form_id: {_eq: $stepper_form_id}}) {
          returning {
            stepper_form_id
          }
          affected_rows
        }
      }
      `,
    getReportList: gql `
   query ReportsList {
     formio_report_filters(order_by: {id: asc}) {
       id
       query_slug
       query_description
     }
   }
   `,
    getJoltById: gql `
      query getJOLT($id:Int!) {
        formio_jolt_specs(where: {id: {_eq: $id}}) {
          id
          jolt_slug
          jolt_specs
          mutation
          description
          form_source
          form_source_filter
        }
      }  
   `,

    addJOLTSpecs: gql `mutation AddJOLTSpecs($data: [formio_jolt_specs_insert_input!]!) {
      insert_formio_jolt_specs(objects: $data, on_conflict: {constraint: jolt_specs_pkey, update_columns: jolt_slug}) {
        returning {
          id
        }
      }
    }`,
  updateJOLTSpecs: gql `mutation UpdateJOLTSpecs($id: Int, $data: formio_jolt_specs_set_input) {
    update_formio_jolt_specs(where: {id: {_eq: $id}}, _set: $data) {
      returning {
        id
      }
    }
  }`
}