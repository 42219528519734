import React from 'react';
import {

} from 'react-bootstrap';
import Utils from '../../Utils';


const Header = (props) => {
    const logoutHandler = (e) =>{        
        Utils.setUserData();
        window.setTimeout(function(){
            window.location.href = process.env.PUBLIC_URL + '/login';
        }, 1000);
    }
    return <div class="topbar">
        <div class="topbar-left">
            <a href="index.html" class="logo"><span>ET-<span>formio-builder</span></span><i class="mdi mdi-layers"></i></a>
        </div>
        <div class="navbar navbar-default" role="navigation">
            <div class="container-fluid">
                <ul class="nav navbar-nav list-inline navbar-left">
                    <li class="list-inline-item">
                        <button class="button-menu-mobile open-left">
                            <i class="mdi mdi-menu"></i>
                        </button>
                    </li>
                    <li class="list-inline-item">
                        <h4 class="page-title">Master</h4>
                    </li>
                </ul>
                <nav class="navbar-custom">
                    <ul class="list-unstyled topbar-right-menu float-right mb-0">
                        <li>
                            <div class="notification-box">
                                <ul class="list-inline mb-0">
                                    <li>
                                        <a href="http://et">
                                            <i class="mdi mdi-bell-outline noti-icon"></i>
                                        </a>
                                        <div class="noti-dot">
                                            <span class="dot"></span>
                                            <span class="pulse"></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li class="hide-phone">
                            <form class="app-search">
                                <input type="text" placeholder="Search..."
                                    class="form-control" />
                                <button type="submit"><i class="fa fa-search"></i></button>
                            </form>
                        </li>
                        <li>
                            <div className="notification-box mt-3 pt-1 mx-3 ">
                            <span onClick={logoutHandler} >
                                <i class="fa fa-sign-out fa-2x clickable" aria-hidden="true"></i></span>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
}

export default Header;