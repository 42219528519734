import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Container, Col, Button, Accordion } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastHeader from 'react-bootstrap/ToastHeader';
import { Form as Formio } from 'react-formio';
import { Forms as formsGQL , JOLT as joltGQL } from './../../graphql';
import Apollo from '../../Apollo';
import { useParams } from 'react-router-dom';
import "../Formio/FormioBuilder.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var reportFilterJson = {};


function IsJsonString(val) {
    try {
        JSON.parse(val);
    } catch (e) {
        return false;
    }
    return true;
}

const JOLTSpecsBuilder = () => {
    const { id } = useParams();
    const [formSchema, setFormSchema] = useState({});
    const [textboxValue, setTextboxValue] = useState();
    const [formId, setFormId] = useState(id);
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState();
    const handleShow = () => setShow(true);
    const [submission,setSubmision] = useState({});

    useEffect(() => {
        const idInt = parseInt(formId);
        Apollo.query(formsGQL.findByTitle, { title: 'et-jolt-transformation-template' }, res => {
             setFormSchema((res.data.formio_forms[0].comp));
         });
         console.log(joltGQL.getJoltById,idInt)
        Apollo.query(joltGQL.getJoltById, { id: idInt }, gqlquery => {
            if(gqlquery.data) {
                console.log(gqlquery.data.formio_jolt_specs[0].jolt_slug);
                setTitle(gqlquery.data.formio_jolt_specs[0].jolt_slug);
                let joltFilterJson = gqlquery.data.formio_jolt_specs[0];

                console.log(joltFilterJson.jolt_specs);
                
       
                setSubmision(joltFilterJson);
        }
        });
    }, [formId]);
    const valueSave = async () => {
        console.log(joltGQL.updateJOLTSpecs);
        console.log(textboxValue);
        await Apollo.mutate(joltGQL.updateJOLTSpecs, { id: formId, data: textboxValue}, res => {
            console.log(res);
            toast("Form Updated!");
        });
        handleShow();
    }
    const handleChange = (val) => {
        const vali = (val.target.value);
        if (IsJsonString(vali)) {
            setFormSchema(JSON.parse(val.target.value));
        }
        else setFormSchema({});
    }

    const submitReportFilterEdit = (f) => {
        console.log(f.data,textboxValue);
        ["submit", "__typename"].forEach(function(key) {
            delete f.data[key];
        });
        setTextboxValue(f.data);
        setSubmision(f.data);
    }


    return <>
        <Container>
            <Card className="card-blue-border">
                <Card.Body>

                    <Row>
                        <Col> <Form.Label>Enter Title</Form.Label></Col>
                        <Col> <Form.Control onChange={(text) => { setTitle(text.target.value); }} value={title} /></Col>
                    </Row>
                    <hr></hr>
                    <Formio form={formSchema} submission={{ data:submission }} onSubmit={submitReportFilterEdit} />
                </Card.Body>
            </Card>
            <hr></hr>
            <Button onClick={valueSave} className="align-center btn-block btn-info btn-clo">Save</Button>
        </Container>

    </>
}

export default JOLTSpecsBuilder
