import React from 'react';
import {

} from 'react-bootstrap';


const Footer = (props) => {
    return <footer class="footer text-right">
        &copy; EddyTools Tech Solutions
    </footer>
}

export default Footer;

