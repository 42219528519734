export const Sections = {
    schema_table: 'formio_forms',
    fields: [{
        dataField: 'id',
        text: 'ID'
    }, {
        dataField: 'comp',
        text: 'comp'
    },
    {
        dataField: 'title',
        text: 'Title'
    }
]
};