import React from 'react';
import {
    Card,
} from 'react-bootstrap';


const Dashboard = (props) => {
    return <Card className="card-blue-border">
        Dashboard
    </Card>
}

export default Dashboard;