import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Container, Col, Button, Accordion } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastHeader from 'react-bootstrap/ToastHeader';
import { FormBuilder, Form as Formio } from 'react-formio';
import { Forms as formsGQL } from './../../graphql';
import Apollo from '../../Apollo';
import { useParams } from 'react-router-dom';
import "./FormioBuilder.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function IsJsonString(val) {
    try {
        JSON.parse(val);
    } catch (e) {
        return false;
    }
    return true;
}

const FormioBuilder = () => {
    const { id } = useParams();
    //console.log(useParams());
    //console.log("id",id);
    const [formSchema, setFormSchema] = useState({});
    const [reload, setReload] = useState(0);
    const [textboxValue, setTextboxValue] = useState();
    const [formId, setFormId] = useState(id);
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState();
    //const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const idInt = parseInt(formId);
        Apollo.query(formsGQL.findById, { id: idInt }, res => {
           ////console.log("res",res);
            setTextboxValue(JSON.stringify(res.data.formio_forms[0].comp));
            setFormSchema((res.data.formio_forms[0].comp));
            setTitle(res.data.formio_forms[0].title);
        });
    }, [formId]);
    const valueSave = async () => {
        ////console.log("button clicked", title.target);
        await Apollo.mutate(formsGQL.update, { id: formId, comp: JSON.parse(textboxValue), title: title }, res => {
          console.log(res);
            toast("Form Updated!");
        });
        handleShow();
    }
    const handleChange = (val) => {
        //console.log(val.target.value);
        // Validate JSON
        const vali = (val.target.value);
        //console.log(IsJsonString(vali));
        if (IsJsonString(vali)) {
            setFormSchema(JSON.parse(val.target.value));
        }
        else setFormSchema({});
        setReload(1);
    }


    return <>
        <Container>
            <Card className="card-blue-border">
                <Card.Body>

                    <Row>
                        <Col> <Form.Label>Enter Title</Form.Label></Col>
                        <Col> <Form.Control onChange={(text) => { setTitle(text.target.value); }} value={title} /></Col>
                    </Row>
                    <hr></hr>
                    <FormBuilder form={formSchema} flag={reload} onChange={(schema) => setTextboxValue(JSON.stringify(schema))} />
                </Card.Body>
            </Card>
            <hr></hr>
        </Container>

        <Container>
            <Card className="card-blue-border">
            <Card.Header>
                <ToastContainer/>
                </Card.Header>
                <Card.Body>
                    <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide>
                        <ToastHeader>
                            <strong className="mr-auto">Saved</strong>
                        </ToastHeader>
                    </Toast>
                    <Button onClick={valueSave} className="align-center btn-block btn-info">Save</Button>
                    <Accordion>
                        <Card.Header>
                            <Accordion.Toggle as={Button} eventKey="0">
                                Import
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body><Form.Control as="textarea" value={textboxValue} rows="10" onChange={handleChange} /></Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </Card.Body>
            </Card>

        </Container>

    </>
}

export default FormioBuilder
