import React from "react";
import PropTypes from "prop-types";
import { } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import SidePanel from './components/SidePanel';

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
    return (
        <>
            <div id="wrapper">
                <Header></Header>
                <SidePanel></SidePanel>
                <div class="content-page">
                    <div class="content">
                        <div class="container-fluid">
                            {children}
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    );
}

DefaultLayout.propTypes = {
    noNavbar: PropTypes.bool,
    noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
    noNavbar: true,
    noFooter: true
};

export default DefaultLayout;
