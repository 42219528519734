import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Container, Col, Button, Accordion } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastHeader from 'react-bootstrap/ToastHeader';
import { Form as Formio } from 'react-formio';
import { Forms as formsGQL , CMS as cmsGQL } from './../../graphql';
import Apollo from '../../Apollo';
import { useParams } from 'react-router-dom';
import "../Formio/FormioBuilder.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var reportFilterJson = {};


function IsJsonString(val) {
    try {
        JSON.parse(val);
    } catch (e) {
        return false;
    }
    return true;
}

const CMSDrawerBuilder = () => {
    const { id } = useParams();
    const [formSchema, setFormSchema] = useState({});
    const [textboxValue, setTextboxValue] = useState();
    const [cmsId, setCMSId] = useState(id);
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState();
    const handleShow = () => setShow(true);
    const [submission,setSubmision] = useState({});

    useEffect(() => {
        const idInt = parseInt(cmsId);
        Apollo.query(formsGQL.findByTitle, { title: 'et-cms-drawer-template' }, res => {
             setFormSchema((res.data.formio_forms[0].comp));
         });
        Apollo.query(cmsGQL.getCMSDrawerById, { id: idInt }, gqlquery => {
            if(gqlquery.data) {
                console.log(gqlquery.data.formio_cms_drawers[0]);
                setTitle(gqlquery.data.formio_cms_drawers[0].cms_drawer_slug);
                let cmsDrawersJson = gqlquery.data.formio_cms_drawers[0];
                setSubmision(cmsDrawersJson);
        }
        });
    }, [cmsId]);
    const valueSave = async () => {
        await Apollo.mutate(cmsGQL.UpdateCMSDrawer, { id: cmsId, data: textboxValue}, res => {
            toast("Form Updated!");
        });
        handleShow();
    }


    const submitCMSDrawerEdit = (f) => {
        ["submit", "__typename"].forEach(function(key) {
            delete f.data[key];
        });
        setTextboxValue(f.data);
        setSubmision(f.data);
    }


    return <>
        <Container>
            <Card className="card-blue-border">
                <Card.Body>

                    <Row>
                        <Col> <Form.Label>Enter Title</Form.Label></Col>
                        <Col> <Form.Control onChange={(text) => { setTitle(text.target.value); }} value={title} /></Col>
                    </Row>
                    <hr></hr>
                    <Formio form={formSchema} submission={{ data:submission }} onSubmit={submitCMSDrawerEdit} />
                </Card.Body>
            </Card>
            <hr></hr>
            <Button onClick={valueSave} className="align-center btn-block btn-info btn-clo">Save</Button>
        </Container>

    </>
}

export default CMSDrawerBuilder
