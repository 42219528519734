export const Languages = {
    "_id": "5ed11dda1aff9e15205abfaf",
    "type": "form",
    "tags": [],
    "owner": "5ebea8db9cb6ee1b0e343e7a",
    "components": [{
        "autofocus": false,
        "input": true,
        "tableView": true,
        "inputType": "text",
        "inputMask": "",
        "label": "Language Label",
        "key": "name",
        "placeholder": "",
        "prefix": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": "",
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "spellcheck": true,
        "validate": {
            "required": false,
            "minLength": "",
            "maxLength": "",
            "pattern": "",
            "custom": "",
            "customPrivate": false
        },
        "conditional": {
            "show": "",
            "when": null,
            "eq": ""
        },
        "type": "textfield",
        "labelPosition": "top",
        "inputFormat": "plain",
        "tags": [],
        "properties": {}
    }, {
        "autofocus": false,
        "input": true,
        "tableView": true,
        "inputType": "text",
        "inputMask": "",
        "label": "Language Code",
        "key": "code",
        "placeholder": "",
        "prefix": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": "",
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "spellcheck": true,
        "validate": {
            "required": false,
            "minLength": "",
            "maxLength": "",
            "pattern": "",
            "custom": "",
            "customPrivate": false
        },
        "conditional": {
            "show": "",
            "when": null,
            "eq": ""
        },
        "type": "textfield",
        "labelPosition": "top",
        "inputFormat": "plain",
        "tags": [],
        "properties": {}
    }, {
        "autofocus": false,
        "input": true,
        "tableView": true,
        "inputType": "text",
        "inputMask": "",
        "label": "ISO",
        "key": "iso",
        "placeholder": "",
        "prefix": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": "",
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "spellcheck": true,
        "validate": {
            "required": false,
            "minLength": "",
            "maxLength": "",
            "pattern": "",
            "custom": "",
            "customPrivate": false
        },
        "conditional": {
            "show": "",
            "when": null,
            "eq": ""
        },
        "type": "textfield",
        "labelPosition": "top",
        "inputFormat": "plain",
        "tags": [],
        "properties": {}
    }, {
        "autofocus": false,
        "input": true,
        "label": "Submit",
        "tableView": false,
        "key": "submit",
        "size": "md",
        "leftIcon": "",
        "rightIcon": "",
        "block": false,
        "action": "submit",
        "disableOnInvalid": false,
        "theme": "primary",
        "type": "button"
    }],
    "controller": "",
    "revisions": "",
    "_vid": 0,
    "title": "master_db",
    "display": "form",
    "access": [{
        "roles": ["5ed0d592d214e0104b27ff06", "5ed0d592d214e0b78227ff07", "5ed0d592d214e0f2de27ff08"],
        "type": "read_all"
    }],
    "submissionAccess": [],
    "settings": {},
    "properties": {},
    "name": "masterDb",
    "path": "masterdb",
    "project": "5ed0d592d214e046c427ff05",
    "created": "2020-05-29T14:36:10.871Z",
    "modified": "2020-05-29T14:36:10.875Z",
    "machineName": "zjhssddfghffgis:masterDb"
}