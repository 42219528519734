import React from 'react';
import { Link } from "react-router-dom";

import { navmenu } from "../../navmenu";


const SidePanel = (props) => {
    return <div class="left side-menu">
        <div class="sidebar-inner slimscrollleft">
            <div id="sidebar-menu">
                <ul>
                    <li class="text-muted menu-title">Master Database</li>
                    <li>
                        {
                            navmenu.map((item, index) => (
                                <Link to={item.path}><i class={item.icon}></i> <span> {item.label} </span> </Link>
                            ))
                        }
                    </li>
                </ul>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
}

export default SidePanel;
